"use client";

import {
  FloatingArrow,
  FloatingFocusManager,
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import clsx from "clsx";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Icon } from "../Icon";
import { ContextMenuItems } from "./ContextMenuItems";

export type PropsT = {
  items?: ItemT[];
  children?: ReactNode;
  customContent?: ReactNode;
  position?: PlacementT;
  padding?: number;
  open?: boolean;
  setOpen?: (val: boolean) => void;
  buttonStyles?: string;
  "data-testid"?: string;
  icon?: string;
  iconStyle?: string;
  iconSize?: string;
  buttonLabel?: string;
  labelStyle?: string;
};

export const ContextMenu = (props: PropsT) => {
  const {
    children,
    items,
    customContent,
    position,
    padding = 10,
    open: controlledOpen,
    setOpen: setControlledOpen,
    buttonStyles = "",
    icon = "dots-three-vertical",
    iconStyle,
    iconSize = "20",
    buttonLabel,
    labelStyle,
    "data-testid": dataTestId,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  useEffect(() => {
    if (controlledOpen !== undefined && controlledOpen !== null) {
      setIsOpen(controlledOpen);
    }
  }, [controlledOpen]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    if (setControlledOpen) {
      setControlledOpen(!isOpen);
    }
  };

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: toggleOpen,
    middleware: [
      offset(padding),
      flip(),
      shift({ padding: 12 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
    placement: position ?? "bottom",
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const x = position?.includes("bottom") || position?.includes("top");
  const y = position?.includes("right") || position?.includes("left");

  return (
    <>
      <button
        ref={refs.setReference}
        {...getReferenceProps({
          onClick(event) {
            event.stopPropagation();
          },
        })}
        data-testid={dataTestId || "context-menu-button"}
        className={clsx("transition-colors duration-300", {
          [buttonStyles]: buttonStyles,
          "rounded-full focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200":
            !buttonStyles,
          "flex h-[34px] w-[34px] items-center justify-center rounded-full hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200":
            !children && !buttonStyles,
          "w-fit px-5": buttonLabel && !buttonStyles,
          "bg-gray-100": isOpen && !children,
        })}
      >
        {buttonLabel && <span className={labelStyle}>{buttonLabel}</span>}

        {children ? (
          children
        ) : (
          <Icon icon={icon} iconStyle={iconStyle} size={iconSize} />
        )}

        <span className="sr-only">Context Menu Button</span>
      </button>

      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              className="relative z-20 outline-none"
              data-testid="context-menu-open"
              {...getFloatingProps()}
            >
              <FloatingArrow
                ref={arrowRef}
                context={context}
                strokeWidth={1}
                className={clsx(
                  "fill-white [&>path:first-of-type]:stroke-gray-200 [&>path:first-of-type]:stroke-2",
                  {
                    "w-16": x,
                    "h-16": y,
                  }
                )}
              />

              {customContent ? (
                customContent
              ) : (
                <ContextMenuItems items={items} setOpen={toggleOpen} />
              )}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};
